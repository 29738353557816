<script>
import { app } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { formatSlug } from '@/support/utils/stringHelper'
import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'

const TRANSLATION = {
  description: {
    audience: app.$t('global:form.tracks.audience'),
    description: app.$t('global:form.tracks.description'),
    differentials: app.$t('global:form.tracks.differentials')
  }
}

const RICH_TEXT_EDITOR_TOOLBAR = [
  [
    'bold',
    'italic',
    'underline',
    'strike'
  ],
  [ 'clean' ],
  [ 'link' ]
]

export default {
  name: 'ProgramsManage',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Icon: () => import('@/components/general/Icon'),
    Radio: () => import('@/components/general/Radio'),
    Upload: () => import('@/components/general/Upload'),
    Action: () => import('@/components/general/Action'),
    Divisor: () => import('@/components/general/Divisor'),
    Checkbox: () => import('@/components/general/Checkbox'),
    Datatable: () => import('@/components/general/Datatable'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    Pagination: () => import('@/components/general/Pagination'),
    MoneyField: () => import('@/components/general/MoneyField'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    TextareaField: () => import('@/components/general/TextareaField'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    AppMultiselect: () => import('@/components/general/AppMultiselect'),
    FilterListSearch: () => import('@/components/general/FilterListSearch')
  },

  data () {
    return {
      linkToProgramTabActive: 0,
      activeTab: 1,
      linkToProgramTab: [
        { text: this.$t('track.manage:link.tab.1') },
        { text: this.$t('track.manage:link.tab.2') }
      ],

      showSolutions: false,
      solutions: [],
      groupList: [],
      showThematicPages: false,
      thematicPages: [],
      programTypeList: [
        {
          text: this.$t('programs.manage:programTypeList.ead'),
          value: 'curso_ead'
        },
        {
          text: this.$t('programs.manage:programTypeList.presential'),
          value: 'presencial'
        },
        {
          text: this.$t('programs.manage:programTypeList.hybrid'),
          value: 'hybrid'
        }
      ],

      themeList: [],
      entrepreneurProfileList: [],
      certificateTypeList: [],
      certificateOptions: [
        {
          label: this.$t('global:form.tracks.yes'),
          value: true
        },
        {
          label: this.$t('global:form.tracks.no'),
          value: false
        }
      ],

      timeDurationList: [
        {
          text: this.$t('global.time:select.m'),
          value: 'min'
        },
        {
          text: this.$t('global.time:select.h'),
          value: 'h'
        },
        {
          text: this.$t('global.time:select.d'),
          value: 'd'
        },
        {
          text: this.$t('global.time:select.s'),
          value: 's'
        }
      ],

      restrictedAccessOptions: [
        {
          label: this.$t('global:unrestricted'),
          value: false
        },
        {
          label: this.$t('global:restricted'),
          value: true
        }
      ],

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      hasCertificate: false,
      satisfactionSurveyList: [],
      subscriptionSurveyList: [],
      configurationList: [],
      formData: {
        id: null,
        name: null,
        slug: null,
        description: null,
        image: null,
        cardImage: null,
        conclusionDeadline: null,
        callText: null,
        audience: null,
        differentials: null,
        showOnPortal: false,
        exclusiveToCompany: false,
        exclusiveToThematicPages: false,
        portfolioId: null,
        sasId: null,
        seoTitle: null,
        seoDescription: null,
        seoKeywords: null,
        certificate: null,
        restrictedAccess: false,
        solutionGroups: [],
        programThemes: [],
        programEntrepreneurProfiles: [],
        programSolutions: [],
        thematicPagesProgram: [],
        automaticWorkload: false,
        surveyEnabled: false,
        surveyId: null,
        subscriptionSurveyEnabled: false,
        surveyRequired: false,
        subscriptionSurveyId: null,
        surveyRequiredForCertificate: false,
        workload: {
          type: null,
          number: null
        },

        tags: null,

        paymentType: 'free',
        price: null,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' #',
          precision: 2,
          masked: false /* doesn't work with directive */
        },

        registrationStatus: 'open'
      },

      pagination: {
        page: 1,
        lastPage: 4
      },

      backUrl: { name: 'programs.index' },
      modalLeave: false,
      modalActiveProgramOpen: false,
      isSlugDuplicate: false,
      formattedUrl: '',
      isLoadingMoreSolutions: false,
      isLoadingMoreThematicPages: false,

      solutionsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      thematicPagesPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      solutionInfiniteScrollId: new Date()
        .getTime(),

      thematicPageInfiniteScrollId: new Date()
        .getTime(),

      paymentTypeOptions: [
        {
          label: this.$t('global:free'),
          value: 'free'
        },
        {
          label: this.$t('global:paid'),
          value: 'paid'
        }
      ],

      minimumSolutionPrice: 0.0,
      minimumSolutionPriceErrorMessage: null,

      registrationStatusOptions: [
        {
          label: this.$t('global:registration.status.option.open'),
          value: 'open'
        },
        {
          label: this.$t('global:registration.status.option.suspended'),
          value: 'suspended'
        }
      ]
    }
  },

  validations () {
    return {
      hasCertificate: {
        required: (event) => {
          return event !== null && event !== undefined
        }
      },

      formData: {
        name: { required },
        slug: {
          required,
          regexSlug: (event) => {
            const regexSlug = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')

            return regexSlug.test(event)
          },

          slugDuplicate: () => {
            return this.isSlugDuplicate
          }
        },

        description: { required },
        image: { required },
        cardImage: { required },
        conclusionDeadline: {
          valueBiggerZero: (value) => {
            if (!value) {
              return true
            }

            return value > 0
          }
        },

        restrictedAccess: { required },

        solutionGroups: {
          required: requiredIf(function () {
            return this.restrictedAccess
          }),

          minGroupsLength: (value, formData) => {
            return formData.restrictedAccess ? value.length > 0 : true
          }
        },

        callText: { required },
        audience: { required },
        differentials: { required },
        showOnPortal: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        exclusiveToCompany: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        certificate: {
          required: requiredIf(function () {
            return !!this.hasCertificate
          })
        },

        automaticWorkload: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        workload: {
          number: {
            required: requiredIf(function () {
              return !this.formData.automaticWorkload && this.activeTab === 2
            }),

            valueBiggerZero: function (valorDigitado) {
              return this.formData.automaticWorkload || this.activeTab !== 2 ? true : valorDigitado > 0
            }
          },

          type: {
            required: requiredIf(function () {
              return !this.formData.automaticWorkload && this.activeTab === 2
            })
          }
        },

        portfolioId: {
          valueBiggerZero: (value) => {
            return value ? value > 0 : true
          }
        },

        sasId: {
          valueBiggerZero: (value) => {
            return value ? value > 0 : true
          }
        },

        surveyEnabled: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        surveyId: {
          required: requiredIf(function () {
            return this.formData.surveyEnabled
          })
        },

        subscriptionSurveyEnabled: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        surveyRequired: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        subscriptionSurveyId: {
          required: requiredIf(function () {
            return this.formData.subscriptionSurveyEnabled
          })
        }
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),
    availableSolutions () {
      return this.solutions.filter(solution => !this.formData.programSolutions.find(findSolution => solution.id === findSolution.id))
        .sort((a, b) => {
          if (a > b) return 1
          if (b > a) return -1

          return 0
        })
    },

    availableThematicPages () {
      return this.thematicPages.filter(thematicPage => !this.formData.thematicPagesProgram.find(findThematicPage => thematicPage.id === findThematicPage.id))
        .sort((a, b) => {
          if (a > b) return 1
          if (b > a) return -1

          return 0
        })
    },

    isEditing () {
      return !!(this.$route.params.id || this.formData.id || false)
    },

    isRaeEnabled () {
      return this.activeModules.includes('rae')
    },

    isEcommerceEnabled () {
      return this.activeModules.includes('ecommerce')
    },

    isSasEnabled () {
      return this.activeModules.includes('sas')
    },

    isProgramPaid () {
      return this.formData.paymentType === 'paid'
    },

    shouldDisableRegistrationStatus () {
      return !this.isEcommerceEnabled && this.isProgramPaid
    }
  },

  watch: {
    'formData.name' () {
      if (!this.$v.formData.slug.$dirty && !this.isEditing) {
        this.formData.slug = formatSlug(this.formData.name)
      }
    },

    'formData.slug' (event) {
      this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

      this.debounceEvent(async () => {
        this.setFetching(true)
        const search = { slug: event }

        if (this.isEditing) {
          search.not_id = [ this.formData.id ]
        }

        const programs = await this.attemptGetProgramsList({ filter: search })
          .then(pagination => { return pagination.data })

        this.isSlugDuplicate = !(programs.length > 0)

        if (!this.isSlugDuplicate) {
          this.$v.formData.slug.$touch()
        }

        this.setFetching(false)
      }, 1000)

      this.formattedUrl = `${process.env.VUE_APP_PORTAL_URL}programa/${this.formData.slug}`
    },

    'formData.price' (value) {
      this.minimumSolutionPriceErrorMessage = value < this.minimumSolutionPrice ? 'Esse valor é menor do que a parcela mínima' : null
    },

    'formData.paymentType' (value) {
      if (value === 'free' && this.formData.price === 0.0) {
        this.minimumSolutionPriceErrorMessage = null
      } else if (value === 'paid' && !this.isEcommerceEnabled) {
        this.formData.registrationStatus = 'suspended'
      }
    }
  },

  mounted () {
    if (this.$route.params.page) {
      this.activeTab = 2
    }
  },

  created () {
    this.setFetching(true)
    this.activeTab = this.$route.params.tab || 1

    this.setup()
      .then(() => {
        if (this.isEditing) {
          this.attemptManageProgram(this.$route.params.id)
            .then(({ data }) => {
              this.formData = {
                ...this.formData,
                ...data
              }

              this.formData.solutionGroups = data.groupProgram.map(item => {
                return {
                  text: item.group.name,
                  value: item.group.id
                }
              })

              this.formData.restrictedAccess = data.restrictedAccess
              this.fetchConnections(data.groupProgram)
              this.formData.certificate = this.formData.certificate && this.certificateTypeList.find(certificate => certificate.value === this.formData.certificate.id).value
              this.hasCertificate = !!this.formData.certificate

              this.formData.programSolutions = this.formData.programSolutions && this.formData.programSolutions.map(
                ({ required, dependent, solution }) => {
                  return {
                    id: solution.id,
                    name: solution.name,
                    active: solution.active,
                    required: required,
                    dependent: !!dependent,
                    solutionType: solution.solutionType,
                    workload: solution.workload
                  }
                })

              this.formData.thematicPagesProgram = this.formData.thematicPagesProgram &&
            this.formData.thematicPagesProgram.map(({ thematicPage }) => {
              return {
                id: thematicPage.id,
                name: thematicPage.name,
                active: thematicPage.active
              }
            })

              this.formData.workload = this.mountWorkloadObject(this.formData.workload)

              if (this.formData.programThemes.length) {
                this.formData.programThemes = this.formData.programThemes.map(solutionTheme => {
                  return solutionTheme.theme.alias
                })
              }

              if (this.formData.programEntrepreneurProfiles.length) {
                this.formData.programEntrepreneurProfiles = this.formData.programEntrepreneurProfiles.map(entrepreneur => {
                  return entrepreneur.entrepreneurProfile.id
                })
              }

              this.showSolutions = !!(this.formData.programSolutions && this.formData.programSolutions.length > 0)
              this.showThematicPages = !!(this.formData.thematicPagesProgram && this.formData.thematicPagesProgram.length > 0)
              this.formData.showOnPortal = !!this.formData.showOnPortal
              this.formData.exclusiveToCompany = !!this.formData.exclusiveToCompany
              this.formData.exclusiveToThematicPages = !!this.formData.exclusiveToThematicPages
              this.formData.automaticWorkload = !!this.formData.automaticWorkload

              if (data.programSurveys.length > 0) {
                data.programSurveys.forEach(element => {
                  switch (element.survey.type) {
                    case 'satisfaction':
                      this.formData.surveyEnabled = true
                      this.formData.surveyId = element.survey.id
                      break
                    case 'subscription':
                      this.formData.subscriptionSurveyEnabled = true
                      this.formData.subscriptionSurveyId = element.survey.id
                      this.formData.surveyRequired = element.surveyRequired
                      break
                  }
                })
              } else {
                this.formData.surveyEnabled = false
                this.formData.subscriptionSurveyEnabled = false
              }
            })
        }
      })
      .catch(() => {
        this.setFeedback({ message: this.$t('programs.load:fetch.error') })
        this.$router.push({ name: 'programs.index' })
      })
      .finally(() => {
        setTimeout(() => {
          this.setFetching(false)
        }, 500)
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptUpdateProgram',
      'attemptManageProgram',
      'attemptGetThemesList',
      'attemptSaveActiveProgram',
      'attemptGetGeneralSettings',
      'attemptGetThematicPagesList',
      'attemptGetGroupsList',
      'attemptGetSolutionsList',
      'attemptUpdateActiveProgram',
      'attemptGetCertificatesList',
      'attemptGetEntrepreneurProfilesList',
      'attemptToggleActiveProgram',
      'attemptGetProgramsList',
      'attemptGetSurveysList'
    ]),

    prevPage () {
      this.submit()
        .then(() => {
          if (this.activeTab > 1) this.activeTab -= 1

          if (this.activeTab === 2) {
            this.setSeoValues()
          }
        })
    },

    nextPage () {
      this.submit()
        .then(() => {
          this.activeTab += 1

          if (this.activeTab === 2) {
            this.setSeoValues()
          }
        })
    },

    setSeoValues () {
      this.formData.seoTitle = this.formData.seoTitle || this.formData.name
      this.formData.seoDescription = this.formData.seoDescription || this.formData.description
      this.formData.seoKeywords = this.formData.seoKeywords || ''
    },

    mountWorkloadObject (workload) {
      return {
        type: workload ? workload.match(/[hdw|min]+/gi) && workload.match(/[hdw|min]+/gi)[0] : 'h',
        number: workload ? workload.match(/[0-9]+/gi) && workload.match(/[0-9]+/gi)[0] : 0
      }
    },

    formatFormData () {
      const data = {}

      data.id = this.formData.id
      data.name = this.formData.name
      data.slug = this.formData.slug
      data.description = this.formData.description

      data.active = 0

      if (this.isEditing) {
        data.active = this.formData.active
      }

      const filterSolutions = this.formData.programSolutions.filter(solution => {
        return solution.active === 1
      })

      data.workload = `${this.formData.workload.number || ''}${this.formData.workload.type || ''}`
      data.automaticWorkload = this.formData.automaticWorkload ? 1 : 0
      data.image = this.formData.image
      data.cardImage = this.formData.cardImage
      data.conclusionDeadline = this.formData.conclusionDeadline
      data.call_text = this.formData.callText
      data.groups = this.formData.restrictedAccess ? this.formData.solutionGroups.filter(item => item.value !== 'all').map(item => item.value) : []
      data.restricted_access = this.formData.restrictedAccess ? 1 : 0
      data.audience = this.formData.audience
      data.differentials = this.formData.differentials
      data.show_on_portal = this.formData.showOnPortal ? 1 : 0
      data.exclusiveToCompany = this.formData.exclusiveToCompany ? 1 : 0
      data.exclusiveToThematicPages = this.formData.exclusiveToThematicPages ? 1 : 0

      data.portfolio_id = this.formData.portfolioId || null
      data.sas_id = this.formData.sasId || null

      data.seoTitle = this.formData.seoTitle || this.formData.name
      data.seoDescription = this.formData.seoDescription || this.formData.description
      data.seoKeywords = this.formData.seoKeywords || ''
      data.certificate_id = this.hasCertificate ? this.formData.certificate : null
      data.themes = this.formData.programThemes
      data.entrepreneur_profiles = this.formData.programEntrepreneurProfiles
      data.payment_type = this.formData.paymentType
      data.price = this.formData.price

      data.solutions = filterSolutions.map((solution) => {
        return {
          id: solution.id,
          required: solution.required,
          dependent: solution.dependent ? solution.dependent : null
        }
      })

      data.thematicPages = this.formData.thematicPagesProgram.map(thematicPage => thematicPage.id)
      data.tags = this.formData.tags

      data.survey_ids = []

      if (this.formData.surveyEnabled) {
        data.survey_ids.push(this.formData.surveyId)
      }

      if (this.formData.subscriptionSurveyEnabled) {
        data.survey_ids.push(this.formData.subscriptionSurveyId)
        data.survey_required = this.formData.surveyRequired
      }

      data.survey_required_for_certificate = this.formData.certificate && this.formData.surveyEnabled
        ? (this.formData.surveyRequiredForCertificate ? 1 : 0)
        : 0

      data.registrationStatus = this.formData.registrationStatus

      return data
    },

    addToLinkedThematicPage (thematicPageId) {
      const thematicPage = this.thematicPages.find(thematicPage => thematicPage.id === thematicPageId)

      thematicPage.active = 1
      this.formData.thematicPagesProgram.push(thematicPage)
    },

    removeLinkedThematicPage (thematicPageId) {
      this.formData.thematicPagesProgram = this.formData.thematicPagesProgram.filter(
        thematicPage => thematicPage.id !== thematicPageId
      )
    },

    clearLinkedThematicPages (event) {
      if (event) return

      this.formData.thematicPagesProgram = this.formData.thematicPagesProgram.filter(thematicPage =>
        thematicPage.active !== 1
      )
    },

    clearLinkedSolutions (event) {
      if (event) return

      this.formData.programSolutions = this.formData.programSolutions.filter(solution =>
        solution.active !== 1
      )
    },

    addToLinkedSolution (solutionId) {
      const solution = this.solutions.find(solution => solution.id === solutionId)

      solution.active = 1
      solution.required = false
      solution.dependent = false
      this.formData.programSolutions.push(solution)
    },

    removeLinkedSolution (solutionId) {
      this.formData.programSolutions = this.formData.programSolutions.filter(solution => solution.id !== solutionId)
    },

    moveUpLinkedSolution (solutionId) {
      const index = this.formData.programSolutions.findIndex(solution => solution.id === solutionId)

      if (index > 0) {
        const swapSolution = this.formData.programSolutions[index - 1]

        this.$set(this.formData.programSolutions, (index - 1), this.formData.programSolutions[index])
        this.$set(this.formData.programSolutions, index, swapSolution)
      }
    },

    moveDownLinkedSolution (solutionId) {
      const index = this.formData.programSolutions.findIndex(solution => solution.id === solutionId)

      if (index < (this.formData.programSolutions.length - 1)) {
        const swapSolution = this.formData.programSolutions[index + 1]

        this.$set(this.formData.programSolutions, (index + 1), this.formData.programSolutions[index])
        this.$set(this.formData.programSolutions, index, swapSolution)
      }
    },

    submitCreation () {
      this.setFetching(true)
      const formData = this.formatFormData()

      return this.attemptSaveActiveProgram(formData)
        .then(({ data }) => {
          this.formData.id = data.id
          this.formData.cardImage = data.cardImage
          this.formData.image = data.image
          this.formData.active = data.active
          this.setFeedback({ message: this.$t('programs:feedback.created.success') })

          this.$router.push({
            name: 'programs.manage',
            params: {
              id: data.id,
              page: 2
            }
          })
        })
        .catch(e => {
          this.setFeedback({ message: this.$t('programs:feedback.created.error') })
          throw e
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()
      const id = this.$route.params.id || this.formData.id

      return this.attemptUpdateProgram({
        id: id,
        data: formData,
        isLinkedTab: this.activeTab === 4 // tab de vinculos
      })
        .then(({ data }) => {
          if (data.imagePath) {
            data.imagePath = data.imagePath + '?v=' + Math.random()
          }

          this.formData.cardImage = data.cardImage
          this.formData.image = data.image
          this.formData.active = data.active
          this.fetchConnections(data.groupProgram)
          this.setFeedback({ message: this.$t('programs:feedback.updated.success') })
        })
        .catch(e => {
          if (e.data.error.message === 'program_solution_not_found') {
            this.setFeedback({ message: this.$t('global:form.programs.toggle.program.solution.not.error') })
          } else {
            this.setFeedback({ message: this.$t('programs:feedback.updated.error') })
          }

          throw e
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit () {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          const submit = this.isEditing ? this.submitUpdate() : this.submitCreation()

          submit.then(() => {
            resolve(true)
          })
            .catch(e => {
              reject(e)
            })
        } else {
          this.setFeedback({ message: this.$t('programs:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    saveAll () {
      this.submit(true)
    },

    getSurveysList () {
      return this.attemptGetSurveysList({ filter: { active: 1 } })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              switch (element.type) {
                case 'satisfaction':
                  this.satisfactionSurveyList.push({
                    text: element.name,
                    value: element.id
                  })

                  break
                case 'subscription':
                  this.subscriptionSurveyList.push({
                    text: element.name,
                    value: element.id
                  })

                  break
              }
            })

            return true
          }
        })
    },

    blur (event) {
      event.target.blur()
    },

    setup () {
      this.TRANSLATION = TRANSLATION
      this.RICH_TEXT_EDITOR_TOOLBAR = RICH_TEXT_EDITOR_TOOLBAR

      return Promise.all([
        this.attemptGetThematicPagesList(this.thematicPagesPagination),
        this.attemptGetSolutionsList(this.solutionsPagination),
        this.attemptGetGroupsList({
          filter: { active: 1 },
          limit: 9999
        }),
        this.attemptGetCertificatesList({ filter: { active: 1 } }),
        this.attemptGetThemesList({ filter: { active: 1 } }),
        this.attemptGetEntrepreneurProfilesList({ filter: { active: 1 } }),
        this.getSurveysList(),
        this.getGeneralSettingsList()
      ])
        .then(([
          thematicPages,
          solutions,
          groups,
          certificates,
          themesList,
          entrepreneurList
        ]) => {
          if (solutions.data) {
            this.solutions = []
            this.solutionsPagination.lastPage = solutions.lastPage
            this.solutionsPagination.page = solutions.actualPage

            solutions.data.forEach(element => {
              this.solutions.push({
                name: element.name,
                id: element.id,
                workload: element.workload,
                solutionType: element.solutionType
              })
            })
          }

          if (groups.data) {
            groups.data.forEach(element => {
              this.groupList.push({
                text: element.name,
                value: element.id
              })
            })
          }

          if (thematicPages.data) {
            this.thematicPages = []
            this.thematicPagesPagination.lastPage = thematicPages.lastPage
            this.thematicPagesPagination.page = thematicPages.actualPage

            thematicPages.data.forEach(element => {
              this.thematicPages.push({
                name: element.name,
                id: element.id,
                thematicPagesProgram: element.thematicPagesProgram,
                thematicPageLearningPaths: element.thematicPageLearningPaths,
                thematicPageSolutions: element.thematicPageSolutions
              })
            })
          }

          if (certificates.data) {
            certificates.data.forEach(element => {
              this.certificateTypeList.push({
                text: element.name,
                value: element.id
              })
            })
          }

          if (themesList.data) {
            themesList.data.forEach(element => {
              this.themeList.push({
                text: element.name,
                value: element.alias
              })
            })
          }

          if (entrepreneurList.data) {
            entrepreneurList.data.forEach(element => {
              this.entrepreneurProfileList.push({
                text: element.name,
                value: element.id
              })
            })
          }
        })
    },

    searchSolution (search) {
      this.solutionsPagination.query.name = search
      this.solutionsPagination.page = 1
      this.solutionsPagination.lastPage = null
      this.solutionsPagination.query.only_name = true
      this.fetchSolutionsData()
    },

    loadMoreSolutions () {
      this.isLoadingMoreSolutions = true

      if (this.solutionsPagination.lastPage) {
        if (this.solutionsPagination.lastPage > this.solutionsPagination.page) {
          this.solutionsPagination.page += 1
        } else {
          this.isLoadingMoreSolutions = false

          return
        }
      }

      this.fetchSolutionsData(true)
        .then(() => {
          this.isLoadingMoreSolutions = false
        })
    },

    async fetchSolutionsData (isLoadMore = false) {
      return this.attemptGetSolutionsList(this.solutionsPagination)
        .then((pagination) => {
          this.solutionInfiniteScrollId += 1
          this.solutionsPagination.lastPage = pagination.lastPage
          this.solutionsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.solutions = []
            }

            pagination.data.forEach(element => {
              this.solutions.push({
                name: element.name,
                id: element.id,
                workload: element.workload,
                solutionType: element.solutionType
              })
            })
          }

          return pagination
        })
    },

    searchThematicPage (search) {
      this.thematicPagesPagination.query.name = search
      this.thematicPagesPagination.page = 1
      this.thematicPagesPagination.lastPage = null
      this.fetchThematicPagesData()
    },

    loadMoreThematicPages () {
      this.isLoadingMoreThematicPages = true

      if (this.thematicPagesPagination.lastPage) {
        if (this.thematicPagesPagination.lastPage > this.thematicPagesPagination.page) {
          this.thematicPagesPagination.page += 1
        } else {
          this.isLoadingMoreThematicPages = false

          return
        }
      }

      this.fetchThematicPagesData(true)
        .then(() => {
          this.isLoadingMoreThematicPages = false
        })
    },

    async fetchThematicPagesData (isLoadMore = false) {
      return this.attemptGetThematicPagesList(this.thematicPagesPagination)
        .then((pagination) => {
          this.thematicPageInfiniteScrollId += 1
          this.thematicPagesPagination.lastPage = pagination.lastPage
          this.thematicPagesPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.thematicPages = []
            }

            pagination.data.forEach(element => {
              this.thematicPages.push({
                name: element.name,
                id: element.id,
                thematicPageLearningPaths: element.thematicPageLearningPaths,
                thematicPageSolutions: element.thematicPageSolutions
              })
            })
          }

          return pagination
        })
    },

    getSolutionTypeFormattedName (solutionType) {
      return solutionTypeFormatter(solutionType)
    },

    getGeneralSettingsList () {
      return this.attemptGetGeneralSettings()
        .then(({ data }) => {
          if (data.data) {
            data.data.forEach(element => {
              this.configurationList.push({
                text: element.field,
                value: element.value
              })
            })

            this.setMinimumSolutionPrice()

            return true
          }
        })
    },

    setMinimumSolutionPrice () {
      const filter = this.configurationList.filter(item => item.text === 'financial_credit_card_minimum_installment_value')

      this.minimumSolutionPrice = filter.length ? parseFloat(filter[0].value) : 0.0
    },

    fetchConnections (groupProgram) {
      if (groupProgram.length > 0) {
        this.setFetching(true)

        Promise.all([
          this.attemptGetSolutionsList(this.solutionsPagination),
          this.attemptGetThematicPagesList(this.thematicPagesPagination)
        ])
          .finally(() => {
            this.setFetching(false)
          })
      }
    },

    addSolutionGroup (item) {
      if (!this.formData.solutionGroups) {
        this.formData.solutionGroups = []
      }

      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = this.groupList
        } else {
          this.formData.solutionGroups.push(item)
        }
      }
    },

    removeSolutionGroup (item) {
      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = []
        } else {
          this.formData.solutionGroups = this.formData.solutionGroups.filter(solutionGroup => solutionGroup.value !== item.value)
        }
      }
    }
  }
}
</script>

<template>
  <div class="main-content programs-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('programs.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.programs')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="saveAll()"
        />
      </template>
    </ContentHeader>

    <div
      v-if="activeTab === 1"
      class="main-content-inner"
    >
      <div class="center">
        <div class="programs-create-header">
          <h2 class="programs-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="programs-create-description"
            v-html="$t('programs.create:header.description')"
          />
        </div>

        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.name"
            :label="$t('global:form.programs.title')"
            :validation="$v.formData.name"
            :counter="100"
          />

          <InputField
            v-model="formData.slug"
            :under-description="formattedUrl"
            :label="$t('global:form.programs.slug')"
            :validation="$v.formData.slug"
            :counter="100"
          />

          <FormSection :title="$t('solution.manage:field.description.restricted.access')">
            <Radio
              v-model="formData.restrictedAccess"
              :under-description="$t('program.manage:field.under.description.restricted.access')"
              :items="restrictedAccessOptions"
              horizontal
              :disabled="isEditing"
              :validation="$v.formData.restrictedAccess"
            />

            <AppMultiselect
              v-if="formData.restrictedAccess"
              v-model="formData.solutionGroups"
              :options="groupList"
              :placeholder="$t('global:form.groups.select')"
              :validation="$v.formData.solutionGroups"
              @onSelect="addSolutionGroup"
              @onRemove="removeSolutionGroup"
            />
          </FormSection>

          <InputField
            v-model="formData.callText"
            :label="$t('global:form.tracks.callText')"
            :validation="$v.formData.callText"
            :counter="250"
          />

          <FormSection :title="TRANSLATION.description.description">
            <RichTextEditor
              v-model="formData.description"
              :validation="$v.formData.description"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="TRANSLATION.description.audience">
            <RichTextEditor
              v-model="formData.audience"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              :validation="$v.formData.audience"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="TRANSLATION.description.differentials">
            <RichTextEditor
              v-model="formData.differentials"
              :validation="$v.formData.differentials"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.feature.title')">
            <Upload
              v-model="formData.image"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:form.tracks.image.description')"
              cropper
              :width="2215"
              :height="830"
              :preview="0.217"
              :validation="$v.formData.image"
            />
          </FormSection>
          <FormSection :title="$t('global:form.tracks.card.image.title')">
            <Upload
              v-model="formData.cardImage"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:form.tracks.card.image.description')"
              cropper
              :width="811"
              :height="270"
              :preview="0.60"
              :validation="$v.formData.cardImage"
            />
          </FormSection>

          <div class="solution-deadline">
            <InputField
              v-model="formData.conclusionDeadline"
              :under-description="$t('solution.manage:field.description.time.to.finish')"
              class="col-8"
              type="number"
              :min="1"
              :label="$t('global:form.solutions.conclusion.deadline')"
              :validation="$v.formData.conclusionDeadline"
              hide-arrows
              @mousewheel="blur($event)"
            />
          </div>

          <FormSection
            v-show="isEcommerceEnabled || (!isEcommerceEnabled && isProgramPaid)"
            :title="$t('global:form.solution.investment')"
          >
            <Radio
              v-model="formData.paymentType"
              :under-description="$t('program.manage:field.description.investment')"
              :items="paymentTypeOptions"
              horizontal
              :disabled="!isEcommerceEnabled && isProgramPaid"
              :validation="$v.formData.paymentType"
            />
          </FormSection>

          <FormSection
            v-show="isProgramPaid"
            :title="$t('global:form.solution.price')"
          >
            <MoneyField
              v-model="formData.price"
              :label="$t('global:form.price')"
              prefix="R$ "
              :disabled="!isEcommerceEnabled"
              :validation="$v.formData.price"
            />
            <span
              v-if="minimumSolutionPriceErrorMessage"
              class="program-price-error-message"
            >{{ minimumSolutionPriceErrorMessage }}</span>
          </FormSection>

          <FormSection :title="$t('global:form.tracks.show.portal')">
            <Radio
              v-model="formData.showOnPortal"
              :items="yesNoOptions"
              horizontal
              :disabled="!isEcommerceEnabled && isProgramPaid"
              :validation="$v.formData.showOnPortal"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.exclusiveToCompany')">
            <Radio
              v-model="formData.exclusiveToCompany"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.exclusiveToCompany"
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.has-certificate')">
            <Radio
              v-model="hasCertificate"
              :items="certificateOptions"
              horizontal
              :validation="$v.hasCertificate"
            />
            <SelectField
              v-if="hasCertificate"
              v-model="formData.certificate"
              :label="$t('global:form.tracks.certificate')"
              :items="certificateTypeList"
              :validation="$v.formData.certificate"
            />
          </FormSection>

          <FormSection :title="$t('global:form.has.subscription.survey')">
            <Radio
              v-model="formData.subscriptionSurveyEnabled"
              :under-description="$t('solution.manage:field.description.has.subscription.survey')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.subscriptionSurveyEnabled"
            />
            <SelectField
              v-if="formData.subscriptionSurveyEnabled"
              v-model="formData.subscriptionSurveyId"
              :label="$t('global:form.survey.type')"
              :items="subscriptionSurveyList"
              :validation="$v.formData.subscriptionSurveyId"
            />
            <Radio
              v-if="formData.subscriptionSurveyEnabled"
              v-model="formData.surveyRequired"
              :under-description="$t('solution.manage:field.description.subscription.survey.required')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.surveyRequired"
            />
          </FormSection>

          <FormSection :title="$t('global:form.has.survey')">
            <Radio
              v-model="formData.surveyEnabled"
              :under-description="$t('solution.manage:field.description.has.survey')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.surveyEnabled"
            />
            <SelectField
              v-if="formData.surveyEnabled"
              v-model="formData.surveyId"
              :label="$t('global:form.survey.type')"
              :items="satisfactionSurveyList"
              :validation="$v.formData.surveyId"
            />
          </FormSection>

          <FormSection
            :title="$t('global:form.registration.status')"
          >
            <Radio
              v-model="formData.registrationStatus"
              :under-description="$t('programs.manage:field.description.registration.status')"
              :items="registrationStatusOptions"
              horizontal
              :disabled="shouldDisableRegistrationStatus"
            />
          </FormSection>

          <FormSection
            v-if="formData.certificate && formData.surveyEnabled"
            :title="$t('solution.manage:field.description.survey.required.certificate')"
          >
            <Radio
              v-model="formData.surveyRequiredForCertificate"
              :under-description="$t('solution.manage:field.description.survey.required.certificate.under-description')"
              :items="yesNoOptions"
              horizontal
            />
          </FormSection>
        </form>
      </div>
    </div>

    <div
      v-if="activeTab === 2"
      class="main-content-inner"
    >
      <div class="center">
        <div class="programs-create-header">
          <h2 class="programs-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="programs-create-description"
            v-html="$t('programs.create:header.description.2')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <FormSection :title="$t('global:form.themes')">
            <SelectField
              v-model="formData.programThemes"
              :label="$t('global:form.theme')"
              multiple
              :items="themeList"
            />
          </FormSection>
          <FormSection :title="$t('global:form.profiles')">
            <SelectField
              v-model="formData.programEntrepreneurProfiles"
              :label="$t('global:form.profile')"
              multiple
              :items="entrepreneurProfileList"
            />
          </FormSection>
          <FormSection :title="$t('global:form.tracks.tags')">
            <TextareaField
              v-model="formData.tags"
              :label="$t('global:form.tracks.tags')"
              :validation="$v.formData.tags"
              auto-grow
            />
          </FormSection>

          <FormSection :title="$t('global:form.tracks.auto.workload')">
            <Radio
              v-model="formData.automaticWorkload"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.automaticWorkload"
            />
          </FormSection>
          <div
            class="form-group clearfix"
            data-gap="40"
            data-cols="2"
          >
            <InputField
              v-model="formData.workload.number"
              type="number"
              :min="0"
              :label="$t('global:solution.workload')"
              :disabled="formData.automaticWorkload"
              :validation="$v.formData.workload.number"
            />
            <SelectField
              v-model="formData.workload.type"
              :label="$t('global:form.type')"
              :items="timeDurationList"
              :disabled="formData.automaticWorkload"
              :validation="$v.formData.workload.type"
            />
          </div>
        </form>
      </div>
    </div>

    <div
      v-if="activeTab === 3"
      class="main-content-inner"
    >
      <div class="center">
        <div class="programs-create-header">
          <h2 class="programs-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="programs-create-description"
            v-html="$t('programs.create:header.description.3')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.seoTitle"
            :label="$t('global:form.seo.title')"
          />
          <InputField
            v-model="formData.seoDescription"
            :label="$t('global:form.seo.description')"
          />
          <InputField
            v-model="formData.seoKeywords"
            :label="$t('global:form.seo.keywords')"
          />
        </form>
      </div>
    </div>

    <div
      v-if="activeTab === 4"
      class="main-content-inner"
    >
      <div class="center">
        <div class="programs-create-header">
          <h2 class="programs-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>
          <p
            class="programs-create-description"
            v-html="$t('programs.create:header.description.4')"
          />
          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>
          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>
        <form
          class="center"
          @submit.prevent="submit()"
        >
          <Tabs
            slot="tabs"
            :links="linkToProgramTab"
            :index-active="linkToProgramTabActive"
            @tabChange="linkToProgramTabActive = $event"
          />

          <div
            v-show="linkToProgramTabActive === 0"
            class="link-selection"
          >
            <FormSection :title="$t('show.in.Solutions')">
              <Radio
                v-model="showSolutions"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedSolutions()"
              />
            </FormSection>
            <div v-if="showSolutions">
              <div class="link-table">
                <div class="linkable-items">
                  <Datatable
                    :is-loading-more="isLoadingMoreSolutions"
                    :items="availableSolutions"
                    hide-horizontal-line
                    has-infinite-scroll
                    has-fade
                    empty-message="no_result"
                    @loadMore="loadMoreSolutions"
                  >
                    <template slot="thead">
                      <th
                        class="th"
                        width="300"
                      >
                        {{ $t('solutions.list.link:datatable.header.1') }}
                      </th>
                    </template>

                    <template slot="tsearch">
                      <FilterListSearch
                        slot="search"
                        :prepend-label="$t('global:form.solution-search')"
                        :hide-error-details="true"
                        class="filter-search"
                        @search="searchSolution"
                      />
                    </template>

                    <template
                      slot="tbody"
                      slot-scope="props"
                    >
                      <tr
                        :key="props.item.id"
                        @click="addToLinkedSolution(props.item.id)"
                      >
                        <td class="td td-full">
                          <span class="td-text bolder">{{ props.item.name }}</span>
                          <br>
                          <span class="td-text td-small">{{ getSolutionTypeFormattedName(props.item.solutionType) }} | {{ props.item.workload }}</span>
                        </td>

                        <td class="td td-button td-small text-right">
                          <Action
                            type="button"
                            icon="keyboard_arrow_right"
                            flat
                          />
                        </td>
                      </tr>
                    </template>
                  </Datatable>
                </div>
                <Divisor orientation="vertical" />
                <div class="linked-items">
                  <Datatable
                    has-fade
                    drag
                    :items="formData.programSolutions"
                    hide-horizontal-line
                  >
                    <template slot="thead">
                      <th class="th">
                        {{ $t('program.solutions.list.link:datatable.header.3') }}
                      </th>
                    </template>
                    <template
                      slot="tbody"
                      slot-scope="props"
                    >
                      <tr
                        v-if="props.item.active"
                        :key="props.item.id"
                        class="tr-draggable"
                      >
                        <td class="td td-drag">
                          <Icon
                            name="drag"
                            size="is-small"
                          />
                        </td>
                        <td class="td td-full">
                          <span class="td-text bolder">{{ props.item.name }}</span>
                          <Checkbox
                            v-model="props.item.required"
                            :items="[{label: 'Obrigatória para conclusão?', value: true}]"
                          />
                          <Checkbox
                            v-if="props.item.id !== formData.programSolutions[0].id"
                            v-model="props.item.dependent"
                            :items="[{label: 'Depende da solução anterior para matricula?', value: true}]"
                          />
                        </td>
                        <td class="td td-button td-small text-right">
                          <div class="ordering-actions">
                            <Action
                              class="order-action"
                              type="button"
                              icon="arrow_drop_down"
                              flat
                              icon-size="large"
                              @click="moveDownLinkedSolution(props.item.id)"
                            />
                            <Action
                              class="order-action"
                              type="button"
                              icon="arrow_drop_up"
                              flat
                              icon-size="large"
                              @click="moveUpLinkedSolution(props.item.id)"
                            />
                          </div>
                          <Action
                            class="order-action"
                            type="button"
                            icon="close"
                            flat
                            @click="removeLinkedSolution(props.item.id)"
                          />
                        </td>
                      </tr>
                    </template>
                  </Datatable>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="linkToProgramTabActive === 1"
            class="link-selection"
          >
            <FormSection :title="$t('show.in.ThematicPages')">
              <Radio
                v-model="showThematicPages"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedThematicPages()"
              />
            </FormSection>
            <FormSection
              v-if="showThematicPages"
              :title="$t('programs.manage:field.exclusiveToThematicPages')"
            >
              <Radio
                v-model="formData.exclusiveToThematicPages"
                :items="yesNoOptions"
                horizontal
              />
            </FormSection>
            <div
              v-if="showThematicPages"
              class="link-table"
            >
              <div class="linkable-items">
                <Datatable
                  :is-loading-more="isLoadingMoreThematicPages"
                  :items="availableThematicPages"
                  hide-horizontal-line
                  has-infinite-scroll
                  has-fade
                  empty-message="no_result"
                  @loadMore="loadMoreThematicPages"
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('themes.list:datatable.header.1') }}
                    </th>
                  </template>
                  <template slot="tsearch">
                    <FilterListSearch
                      slot="search"
                      class="filter-search"
                      :prepend-label="$t('global:form.theme-search')"
                      :hide-error-details="true"
                      @search="searchThematicPage"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :key="props.item.id"
                      @click="addToLinkedThematicPage(props.item.id)"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                        <br>
                        <span class="td-text td-small">{{ props.item.thematicPageLearningPaths.length }} {{ props.item.thematicPageLearningPaths.length === 1 ? 'Trilha' : 'Trilhas' }} | {{ props.item.thematicPageSolutions.length }} {{ props.item.thematicPageSolutions.length === 1 ? 'Solução' : 'Soluções' }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          flat
                          type="button"
                          icon="keyboard_arrow_right"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
              <Divisor orientation="vertical" />
              <div class="linked-items">
                <Datatable
                  has-fade
                  :items="formData.thematicPagesProgram"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('themes.list:datatable.header.2') }}
                    </th>
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      v-if="props.item.active"
                      :key="props.item.id"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeLinkedThematicPage(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      v-show="activeTab === 5 && (isRaeEnabled || isSasEnabled)"
      class="main-content-inner"
    >
      <div class="center">
        <div class="programs-create-header">
          <h2 class="programs-create-title">
            {{ $t('global:form.step', {'num': activeTab}) }}
          </h2>

          <p
            class="programs-create-description"
            v-html="isRaeEnabled && isSasEnabled ? $t('programs.create:header.description.5') : isSasEnabled ? $t('programs.create:header.description.5.no.rae') : $t('programs.create:header.description.5:no.sas')"
          />
          <span class="under-description">
            {{ isRaeEnabled && isSasEnabled ? $t('programs.create:header.description.5.under-description') : isSasEnabled ? $t('programs.create:header.description.5.under-description.no.rae') : $t('programs.create:header.description.5.under-description:no.sas') }}
          </span>
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-if="isRaeEnabled"
            v-model="formData.portfolioId"
            :under-description="$t('programs.manage:field.description.sebrae.code')"
            type="number"
            :min="1"
            :label="$t('global:form.solutions.portfolio.id')"
            :validation="$v.formData.portfolioId"
            @mousewheel="blur($event)"
          />

          <InputField
            v-if="isSasEnabled"
            v-model="formData.sasId"
            :under-description="$t('programs.manage:field.description.sas.code')"
            type="number"
            :min="1"
            :label="$t('global:form.solutions.sas.id')"
            :validation="$v.formData.sasId"
            @mousewheel="blur($event)"
          />
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="activeTab"
      :page-count="(isRaeEnabled || isSasEnabled) ? 5 : 4"
      @nextPage="nextPage()"
      @previousPage="prevPage()"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/programs.css"></style>

<style>
.form-input-prepend {
  width: unset;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}

.td-tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  line-height: 30px;
  padding: 0 10px;
  min-width: 70px;
  text-align: center;
  background: #F3F4F7;
  border-radius: 50px;
  text-transform: none;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}

.program-price-error-message {
  color: #ffc14d;
  font-size: 14px;
}
</style>
